import React from "react"
import Styles from "../styles/components/Footer.module.scss"
import { Link } from "gatsby"

const Footer = () => (
  <div className={`container-padding ${Styles.background}`}>
    
    <div className={`${Styles.container}`}>
      <div className={`${Styles["footer-links"]}`}>
        <h5>Helpful Links</h5>
        <Link to="about">About</Link>
        <Link to="blog">Our Thoughts</Link>
        <Link to="case-studies">Case Studies</Link>
        <Link to="contact">Get A Quote</Link>
      </div>
      <div>
        <h5>Our Office</h5>
        <p>Suite A54 PrimalTek Plaza Egbeda Lagos</p>
      </div>
      <div className={`${Styles["footer-links"]}`}>
        <h5>Work With Us</h5>
        <a href="mailto:hello@phosmobile.com">hello@phosmobile.com</a>
        <a>(+234) 09026834734</a>
      </div>
      <div className={`${Styles["footer-links"]}`}>
        <h5>Social</h5>
        <a>Facebook</a>
        <a>Instagram</a>
        <a>Dribble</a>
        <a>Twitter</a>
      </div>
    </div>
    <div className={Styles.copyright}>
      © 2020 PhosMobile. All Rights Reserved
    </div>
  </div>
)

export default Footer
