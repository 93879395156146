import React from "react"
import PhosLogo from "../images/phoslogo.svg"
import NavStyles from "../styles/components/TopNav.module.scss"
import AniLink from "gatsby-plugin-transition-link/AniLink"

const Header = () => (
  <div>
    <header className={NavStyles.header}>
      <div>
        <AniLink
          cover
          to="/"
          direction="right"
          bg="rgba(0, 0, 0)"
          duration={0.3}
        >
          <img src={PhosLogo} alt="PhosMobile Logo" />
        </AniLink>
      </div>

      <nav className={NavStyles.nav}>
        <AniLink
          cover
          to="/"
          direction="right"
          bg="rgba(0, 0, 0)"
          duration={0.3}
          activeClassName="active"
        >
          <h4>Home</h4>
        </AniLink>
        <AniLink
          cover
          to="/case-studies/"
          activeClassName="active"
          bg="rgba(0, 0, 0)"
          duration={0.3}
        >
          <h4>Case Studies</h4>
        </AniLink>
        <AniLink
          cover
          to="/about/"
          bg="rgba(0, 0, 0)"
          duration={0.3}
          activeClassName="active"
        >
          <h4>Our Story</h4>
        </AniLink>

        <AniLink
          cover
          to="/expertise/"
          activeClassName="active"
          bg="rgba(0, 0, 0)"
          duration={0.3}
        >
          <h4>Expertise</h4>
        </AniLink>
        <AniLink
          cover
          to="/blog/"
          activeClassName="active"
          bg="rgba(0, 0, 0)"
          duration={0.3}
        >
          <h4>Blog</h4>
        </AniLink>
        <AniLink
          to="/contact/"
          className={NavStyles.contact}
          activeClassName="active"
          bg="rgba(0, 0, 0)"
          duration={0.3}
        >
          <h4>Say Hi</h4>
        </AniLink>
      </nav>
    </header>
  </div>
)

export default Header
