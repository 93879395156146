import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Styles from "../styles/components/Button.module.scss"

const Button = props => (
  <div className={Styles.container}>
    {!props.secondaryButton && <AniLink
    direction="up"
      cover
      bg="rgba(0, 0, 0)"
      duration={0.3}
      to={props.buttonUrl}
      className={Styles["cta-button"]}
    >
      <h4>{props.buttonTitle}</h4>
    </AniLink>}
    {props.secondaryButton && <AniLink
    direction="up"
      cover
      bg="rgba(0, 0, 0)"
      duration={0.3}
      to={props.buttonUrl}
      className={Styles["secondary-cta-button"]}
    >
      <h4>{props.buttonTitle}</h4>
    </AniLink>}

  </div>
)

export default Button
